import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { motion } from 'framer-motion';

import DirtLinks from './DirtLinks';

export default function DirtHeader(props) {
   return (
      <main>
         <header
            className='w-full bg-cover bg-center py-24 px-4 sm:px-6 lg:px-8'
            style={{ backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.73)),url(${props.bg}) ` }}
         >
            <div className='max-w-7xl mx-auto flex flex-col items-center md:block'>
               <div className='text-4xl lg:text-6xl text-center md:text-left md:pl-2 my-8 md:border-l-8 md:border-dirt font-bold text-white'>
                  <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }} className='mb-4'>
                     {props.header1}
                  </motion.h1>
                  <motion.h1 initial={{ opacity: 0, y: -15 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1 }}>
                     {props.header2}
                  </motion.h1>
               </div>
               <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.75, duration: 0.75 }}
                  style={{ fontFamily: 'Muli' }}
                  className='text-secondary  text-center md:text-left md:pl-4 mb-12'
               >
                  {props.subtitle}
               </motion.p>
               <AnchorLink
                  to='/dirt#contact'
                  style={{ fontFamily: 'Muli' }}
                  className='w-max md:ml-4 shadow-lg mt-12 bg-dirt hover:bg-dirt-light hover:text-white  text-xs md:text-base rounded-md text-black px-12 py-2'
               >
                  Contact Us
               </AnchorLink>
            </div>
         </header>
         <DirtLinks />
      </main>
   );
}

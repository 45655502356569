import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import '../styles/global.css';

import DirtLayout from '../components/DirtLayout';
import DirtHeader from '../components/DirtHeader';
import Seo from '../components/Seo';

import background from '../images/Hunter-Environmental-Equipment-Header.jpg';

export default function TrainingVideos({ data }) {
   return (
      <DirtLayout>
         <Seo title='Equipment' />
         <DirtHeader
            bg={background}
            header1='Hunter Environmental'
            header2='Training Videos'
            subtitle='Lorem ipsum dolor sit amet, consetetur. Lorem ipsum dolor sit amet, consetetur'
         />
         <div className='relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8'>
            <div className='absolute inset-0'>
               <div className='bg-white h-1/3 sm:h-2/3' />
            </div>
            <div className='relative max-w-7xl mx-auto'>
               <div className='text-center'>
                  <h2 className='text-3xl tracking-tight font-className= text-gray-900 sm:text-4xl'>Training Videos</h2>
                  <p className='mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4'>
                     Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.
                  </p>
               </div>
               <div className='mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none'>
                  {data.allMdx.nodes.map((node) => (
                     <div key={node.title} className='flex flex-col rounded-lg shadow-lg overflow-hidden'>
                        <div className='flex-shrink-0'>
                           <GatsbyImage
                              className='h-48 w-full object-cover'
                              image={node.frontmatter.hero_image.childImageSharp.gatsbyImageData}
                              alt={node.frontmatter.hero_image_alt}
                           />
                        </div>
                        <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
                           <div className='flex-1'>
                              <p className='text-sm font-medium text-indigo-600'>{node.frontmatter.category}</p>
                              <a href={node.href} className='block mt-2'>
                                 <p className='text-xl font-semibold text-gray-900'>{node.frontmatter.title}</p>
                                 <p className='mt-3 text-base text-gray-500'>{node.frontmatter.description}</p>
                              </a>
                           </div>
                           <div className='mt-6 flex items-center'>
                              <div className='flex-shrink-0'>
                                 <span className='sr-only'>{node.author}</span>
                                 <GatsbyImage
                                    image={node.frontmatter.authorImg.childImageSharp.gatsbyImageData}
                                    className='h-10 w-10 rounded-full'
                                    alt=''
                                 />
                              </div>
                              <div className='ml-3'>
                                 <p className='text-sm font-medium text-gray-900'>{node.frontmatter.author}</p>
                                 <div className='flex space-x-1 text-sm text-gray-500'>
                                    <time>{node.frontmatter.date}</time>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </DirtLayout>
   );
}

export const query = graphql`
   query {
      allMdx {
         nodes {
            frontmatter {
               author
               category
               date(formatString: "MMMM DD, YYYY")
               description
               hero_image {
                  childImageSharp {
                     gatsbyImageData(width: 500)
                  }
               }
               title
               hero_image_alt
               authorImg {
                  childImageSharp {
                     gatsbyImageData(width: 100)
                  }
               }
            }
         }
      }
   }
`;
